import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  useBreakpointValue,
  Container
} from "@chakra-ui/react";
import { useHistory, useRouteMatch, useParams, Switch, Route } from 'react-router-dom';
import WelcomeBanner from "./components/WelcomeBanner";
import ActiveCampaignsTable from './components/ActiveCampaignsTable';
import FeaturedInfluencers from "./components/FeaturedInfluencers";
import Search from './pages/search';

import AccountsDropdown from "./components/accountsDropDown";

import { AccountsDataContext } from "contexts/accountsDataContext";
import ActiveCampaign from "./pages/ActiveCampaign";
import { Influencer, InfluencerCampaign, CollaborationsData } from './types';
import { getClientCampaignsAndCollabs, getInfluencersByIDs } from "services/firebaseService";
import { useToast } from "@chakra-ui/react"; // Add this import
import UpcomingCollabs from "./components/upcomingCollabs";
import CollabCalendar from './components/CollabCalendar';


export default function FoodFluence() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [filteredClientID, setFilteredClientID] = useState<string>("");
  const [influencerCampaigns, setInfluencerCampaigns] = useState<Record<string, InfluencerCampaign>>({});
  const [selectedAccountName, setSelectedAccountName] = useState<string>("");
  const [allCollaborations, setAllCollaborations] = useState<Record<string, CollaborationsData>>({});
  const [allInfluencers, setAllInfluencers] = useState<Record<string, Influencer>>({});

  const accountsData = useContext(AccountsDataContext);

  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [calendarInitialTab, setCalendarInitialTab] = useState(0);

  useEffect(() => {
    const fetchCampaigns = async () => {
      const fetchedCampaigns = await getClientCampaignsAndCollabs(filteredClientID);
      setInfluencerCampaigns(fetchedCampaigns);

      const allCollabs: Record<string, CollaborationsData> = {};
      const influencerIds = new Set<string>();

      Object.values(fetchedCampaigns).forEach(campaign => {
        Object.assign(allCollabs, campaign.collaborations);
        Object.values(campaign.collaborations).forEach(collab => {
          influencerIds.add(collab.influencerID);
        });
      });

      setAllCollaborations(allCollabs);

      const fetchedInfluencers = await getInfluencersByIDs(Array.from(influencerIds));
      setAllInfluencers(fetchedInfluencers);
    };
    fetchCampaigns();
  }, [filteredClientID]);

  const refreshCollabData = useCallback(async () => {
    const fetchedCampaigns = await getClientCampaignsAndCollabs(filteredClientID);
    setInfluencerCampaigns(fetchedCampaigns);

    const allCollabs: Record<string, CollaborationsData> = {};
    const influencerIds = new Set<string>();

    Object.values(fetchedCampaigns).forEach(campaign => {
      Object.assign(allCollabs, campaign.collaborations);
      Object.values(campaign.collaborations).forEach(collab => {
        influencerIds.add(collab.influencerID);
      });
    });

    setAllCollaborations(allCollabs);

    const fetchedInfluencers = await getInfluencersByIDs(Array.from(influencerIds));
    setAllInfluencers(fetchedInfluencers);
  }, [filteredClientID]);

  const handleSearchClick = () => {
    history.push(`${path}/search?clientID=${filteredClientID}`);
  };

  const handleCampaignClick = (campaignId: string) => {
    history.push(`${path}/campaign/${campaignId}`);
  };

  let clientAccounts = [];
  for (let account in accountsData) {
    if (account && accountsData[account]) {
      clientAccounts.push({
        ...accountsData[account],
      });
    }
  }
  const handleAccountNameChange = (name: string) => {
    setSelectedAccountName(name);
  };

  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });

  const toast = useToast(); // Initialize toast for notifications

  

  const FoodFluenceMain = () => {
    return (
      <Box>
        <Box
          backgroundColor="white"
          borderRadius="10px"
          minHeight="calc(100vh - 135px)"
          position="relative"
          display="flex"
          flexDirection="column"
          p={{ base: 2, md: 3 }}
        >
          <Flex justifyContent="space-between" alignItems="flex-start" mb={4}>
            <Box flex="1" ml = {-0} width="auto">
              <WelcomeBanner 
                clientID={filteredClientID} 
                accountName={selectedAccountName} 
                influencerCampaigns={influencerCampaigns} 
                influencers={allInfluencers} 
                collabs={allCollaborations}
                isCalendarModalOpen={isCalendarModalOpen}
                setIsCalendarModalOpen={setIsCalendarModalOpen}
              />
             
            </Box>
            {accountsData && Object.keys(accountsData)?.length > 0 && (
              <Box width={{ base: "100%", md: "auto" }} mt={{ base: 1, md: 0 }}>
                <AccountsDropdown
                  filteredClientId={filteredClientID}
                  clientAccounts={clientAccounts}
                  setFilteredClientId={setFilteredClientID}
                  setSelectedAccountName={handleAccountNameChange}
                  selectedAccountName={selectedAccountName}
                />
              </Box>
            )}
          </Flex>
          
          <Container 
            maxW="container.2xl" 
            position="relative"
            mt={-6}
            px={{ base: 6, md: 8 }}
            zIndex={2}
          >
            <Box 
              width="95%" 
              mx="auto"
            >
              <ActiveCampaignsTable 
                influencerCampaigns={influencerCampaigns}
                onCampaignClick={handleCampaignClick}
              />
            </Box>
          </Container>
          
          <Container 
            maxW="container.2xl" 
            px={{ base: 6, md: 8 }}
          >
            <Box 
              width="95%" 
              mx="auto"
            >
              <Flex gap={8} mt={4}>
                <Box width="30%">
                  <UpcomingCollabs 
                    collabs={allCollaborations} 
                    influencers={allInfluencers}
                    onViewAll={handleViewAllCollabs}
                  />
                </Box>
                <Box width="70%">
                  <FeaturedInfluencers 
                    clientID={filteredClientID} 
                  />
                </Box>
              </Flex>
            </Box>
          </Container>

          <CollabCalendar
            collaborations={allCollaborations}
            influencers={allInfluencers}
            campaigns={influencerCampaigns}
            isOpen={isCalendarModalOpen}
            onClose={() => {
              setIsCalendarModalOpen(false);
              setCalendarInitialTab(0);
            }}
            global={false}
            businessType={accountsData[filteredClientID]?.businessType}
            refreshCollabData={refreshCollabData}
            initialTab={calendarInitialTab}
            setUpcomingCollabsCount={(count) => {}}
          />
        </Box>
      </Box>
    );
  };

  const ActiveCampaignWrapper = () => {
    const { campaignId } = useParams<{ campaignId: string }>();
    const campaign = influencerCampaigns[campaignId];
    
    if (!campaign) {
      return <div>Campaign not found</div>;
    }



    return (
      <ActiveCampaign
        campaign={campaign}
        collaborations={campaign.collaborations}
        clientID = {filteredClientID}
        refreshCollabData={refreshCollabData}
      />
    );
  };

  const handleViewAllCollabs = (tabIndex: number = 0) => {
    setCalendarInitialTab(tabIndex);
    setIsCalendarModalOpen(true);
  };

  return (
    <Switch>
      <Route exact path={path}>
       <FoodFluenceMain />
      </Route>
      <Route path={`${path}/search`}>
          <Search refreshCollabData={refreshCollabData}/>
      </Route>
      <Route path={`${path}/campaign/:campaignId`}>
          <ActiveCampaignWrapper />
      </Route>
    </Switch>
  );
}