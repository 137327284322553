import React from 'react';
import { Flex, Text, Button, Checkbox, HStack } from '@chakra-ui/react';
import InstagramProfilePic from '../../../components/InstagramProfilePic';
import { updateInfluencerBookmarkedByClient } from 'services/firebaseService';
import { FaInstagram, FaTiktok, FaStar } from "react-icons/fa6";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import { Influencer } from '../../../types';

interface InfluencersListProps {
  influencers: Influencer[];
  bookmarkedInfluencers: String[];
  clientID: string;
  creatingCampaign: boolean;
  handleInfluencerSelected: (influencerID: string) => void;
  selectedInfluencers: Set<String>;
}

const InfluencersList: React.FC<InfluencersListProps> = (props) => {
  const { influencers, bookmarkedInfluencers, clientID, creatingCampaign, handleInfluencerSelected, selectedInfluencers } = props;

  function formatFollowersCount(followers: number): string {
    if (followers >= 1000 && followers < 1000000) {
      return (followers / 1000).toFixed(1) + "k";
    } else if (followers >= 1000000) {
      return (followers / 1000000).toFixed(1) + "M";
    }
    return followers.toString();
  }
  return (
    <>
      {influencers.map((influencer) => (
        <Flex key={influencer.id} direction="row" gap={4} mt={4} align="center">
          {creatingCampaign && (
            <Checkbox
              isChecked={selectedInfluencers.has(influencer.id)}
              onChange={() => handleInfluencerSelected(influencer.id)}
              colorScheme='green'
              border={selectedInfluencers.has(influencer.id) ? "0px solid" : "1px solid"}
              borderRadius="sm"
              size='md'
            />
          )}
          <InstagramProfilePic
            profile_pic_url={influencer.profile_pic_url}
            instagramHandle={influencer.instagramHandle}
            imageSize={"80px"}
          />
          <Flex direction="column" gap={2}>
            <HStack>
              <Text fontSize="lg" fontWeight="semibold">
                @{influencer.instagramHandle}
              </Text>
              {influencer.featured && <FaStar color="#0080FE" />}
              <Button
                onClick={() => updateInfluencerBookmarkedByClient(clientID, influencer.id)}
                variant="ghost"
                size="sm"
              >
                {bookmarkedInfluencers && bookmarkedInfluencers.includes(influencer.id) ?
                  (<FaBookmark color="green" />) : (<FaRegBookmark />)}
              </Button>
            </HStack>
            <HStack spacing={2} color="gray.500">
              {influencer.instagramHandle && (
                <Button as="a" href={`https://instagram.com/${influencer.instagramHandle}`} target="_blank" rel="noopener noreferrer" variant="link" size="sm">
                  <FaInstagram />
                </Button>
              )}
              {influencer.tiktokHandle && (
                <>
                  <Text>|</Text>
                  <Button as="a" href={`https://www.tiktok.com/@${influencer.tiktokHandle}`} target="_blank" rel="noopener noreferrer" variant="link" size="sm">
                    <FaTiktok />
                  </Button>
                </>
              )}
              {influencer.state && influencer.state.toLowerCase() !== 'unknown' && (
                <>
                  <Text>|</Text>
                  <Text fontSize="sm">
                    {influencer.city && influencer.city.toLowerCase() !== 'unknown' && `${influencer.city}, `}{influencer.state}
                  </Text>
                </>
              )}
              {influencer.follower_count && (
                <>
                  <Text>|</Text>
                  <Text fontSize="sm">
                    {formatFollowersCount(influencer.follower_count)} followers
                  </Text>
                </>
              )}
            </HStack>
          </Flex>
        </Flex>
      ))}
    </>
  )
};

export default InfluencersList;