import { VStack, Flex, HStack, Box, Text, IconButton, Icon } from "@chakra-ui/react";
import { FaMapMarkerAlt, FaTrash } from "react-icons/fa";
import { FoodfluenceLocation } from "../types"; // Adjust import path as needed

interface LocationListProps {
  locations: FoodfluenceLocation[];
  onRemoveLocation: (locationId: string) => void;
}

export const LocationList = ({ locations, onRemoveLocation }: LocationListProps) => {
  return (
    <VStack align="stretch" spacing={2} maxH="200px" overflowY="auto">
      {locations.length > 0 &&
        locations.map((location) => (
          <Flex
            key={location.fullAddress}
            p={3}
            bg="gray.50"
            borderRadius="md"
            alignItems="center"
            justifyContent="space-between"
            boxShadow="sm"
            _hover={{ bg: "gray.100" }}
          >
            <HStack spacing={3}>
              <Icon as={FaMapMarkerAlt} color="green.500" />
              <Box>
                <Text>{location.fullAddress}</Text>
                <Text fontSize="sm" color="gray.600">
                  {`${location.city}, ${location.state} ${location.zip}`}
                </Text>
              </Box>
            </HStack>
            <IconButton
              aria-label="Remove location"
              icon={<FaTrash />}
              size="sm"
              colorScheme="red"
              variant="ghost"
              onClick={() => onRemoveLocation(location.fullAddress)}
            />
          </Flex>
        ))}
    </VStack>
  );
}; 