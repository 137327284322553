// Chakra imports
import { Box, Flex, Spinner, useDisclosure } from "@chakra-ui/react";
// Layout components
import Navbar from "components/navbar/NavbarAdmin";
import Sidebar from "components/sidebar/Sidebar";
import {
  AccountsDataContext,
} from "contexts/accountsDataContext";
import LocationsDataProvider from "contexts/locationsDataContext";
import OffersDataProvider from "contexts/offersDataContext";
import { SidebarContext } from "contexts/sidebarContext";
import { useContext, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import routes from "routes";
import AccountOverview from "views/admin/accounts/pages/accountOverview/AccountOverview";
import AddAccount from "views/admin/accounts/pages/addAccount/AddAccount";
import AddLocation from "views/admin/accounts/pages/locations/pages/AddLocation";
import LocationOverview from "views/admin/accounts/pages/locations/pages/LocationOverview";
import AccountOffers from "views/admin/accounts/pages/offers/AccountOffers";
import CreateOffer from "views/admin/accounts/pages/offers/pages/CreateOffer";
import OfferOverview from "views/admin/accounts/pages/offers/pages/OfferOverview";
import AllCustomerGroups from "views/admin/community/pages/AllCustomerGroups";
import CustomerList from "views/admin/community/pages/CustomerList";
import AccountLocations from "../../views/admin/accounts/pages/locations/AccountLocations";
import AddOrEditCustomerGroup from "../../views/admin/community/pages/AddOrEditCustomerGroup";
import ViewCustomerProfile from "../../views/admin/community/pages/ViewCustomerProfile";
import CreateAutoPilotOffer from '../../views/admin/autopilot/CreateAutoPilotOffer';
import CustomerGroupsProvider from "contexts/customerGroupsDataContext";
import OfferLayout from "views/admin/accounts/pages/new-offer-flow/component/OfferLayout";
import { AdminDataContext } from "contexts/adminDataContext";

export default function DashboardWrapped(props: { [x: string]: any }) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { onOpen } = useDisclosure();

  const accountsData = useContext(AccountsDataContext);
  const { adminData, isLoading } = useContext(AdminDataContext);
  // get all offer ids and location ids from accountsData, where accountsData is a map
  const allOfferIds = [];
  const allLocationIds = [];
  const allCustomerGroupIds = [];

  const visibleRoutes = routes.filter(route => {
    if (adminData.disableStoryItAccess) {
      return ['FoodFluence', 'Settings'].includes(route.name);
    }
    return route.name !== 'FoodFluence' || adminData.foodfluenceAccess;
  });

  const location = useLocation();
  const isFoodfluencePage = location.pathname.includes('/admin/foodfluence');

  if (!accountsData) {
    return (
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Flex>
    );
  }
  for (let account in accountsData) {
    allOfferIds.push(...accountsData[account]?.offers);
    allLocationIds.push(...accountsData[account]?.locations);
    allCustomerGroupIds.push(...(accountsData[account]?.customerGroups ?? []));
  }

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = adminData.disableStoryItAccess ? "FoodFluence" : "StoryIt";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === "/admin") {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <CustomerGroupsProvider customerGroupIds={allCustomerGroupIds}>
      <LocationsDataProvider locationIds={allLocationIds}>
        <Box>
          <SidebarContext.Provider
            value={{
              toggleSidebar,
              setToggleSidebar,
            }}
          >
            <Sidebar routes={visibleRoutes} display="none" {...rest} />
            <Box
              float="right"
              minHeight="100vh"
              height="100%"
              overflow="auto"
              position="relative"
              maxHeight="100%"
              w={{ base: "100%", xl: "calc( 100% - 250px )" }}
              maxWidth={{ base: "100%", xl: "calc( 100% - 250px )" }}
              transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
              transitionDuration=".2s, .2s, .35s"
              transitionProperty="top, bottom, width"
              transitionTimingFunction="linear, linear, ease"
            >
              {!isFoodfluencePage && (
                <Navbar
                  onOpen={onOpen}
                  pageTitle={getActiveRoute(visibleRoutes)}
                  secondary={getActiveNavbar(visibleRoutes)}
                  fixed={fixed}
                  {...rest}
                />
              )}
              {getRoute() ? (
                <Box
                  mx="auto"
                  p={{ base: "20px", md: "30px" }}
                  pe="20px"
                  minH="calc(100vh - 75px)"
                  pt={isFoodfluencePage ? "0" : "50px"}
                  bg={isFoodfluencePage ? "white" : "gray.50"}
                >
                  <Switch>
                    <Route exact path={`/admin/accounts/view-account/`}>
                      <AccountOverview />
                    </Route>
                    <Route exact path={"/admin/accounts/create-account/"}>
                      <AddAccount />
                    </Route>
                    <Route exact path={"/admin/add-location/"}>
                      <AddLocation />
                    </Route>
                    <Route
                      exact
                      path={"/admin/community/create-customer-group/"}
                    >
                      <AddOrEditCustomerGroup />
                    </Route>
                    <Route
                      exact
                      path={"/admin/community/view-all-customer-groups/"}
                    >
                      <AllCustomerGroups />
                    </Route>
                    <Route exact path={"/admin/create-offer/"}>
                      <OfferLayout />
                    </Route>
                    {/* <Route exact path={"/admin/create-new-offer-flow/"}>

                        <OfferLayout />
                      </Route> */}
                    <Route exact path={"/admin/create-autopilot-offer/"}>
                      <CreateAutoPilotOffer />
                    </Route>
                    <Route exact path={"/admin/view-all-offers/"}>
                      <AccountOffers />
                    </Route>
                    <Route exact path={"/admin/view-all-locations/"}>
                      <AccountLocations />
                    </Route>
                    <Route exact path={"/admin/view-all-customers/"}>
                      <CustomerList />
                    </Route>
                    <Route exact path={"/admin/view-customer/"}>
                      <ViewCustomerProfile />
                    </Route>
                    <Route exact path={"/admin/view-location/"}>

                      <LocationOverview />
                    </Route>
                    <Route exact path={"/admin/view-offer/"}>
                      <OfferOverview />
                    </Route>
                    {getRoutes(visibleRoutes)}
                    <Redirect from="/" to={adminData.disableStoryItAccess ? "/admin/foodfluence" : "/admin/overview"} />
                  </Switch>
                </Box>
              ) : (
                <div>
                  An error has occurred. Please contact support at
                  team@storyit.us
                </div>
              )}
              <Box></Box>
            </Box>
          </SidebarContext.Provider>
        </Box>
      </LocationsDataProvider>
    </CustomerGroupsProvider>
  );
}