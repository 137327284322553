import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  ModalCloseButton,
  Spinner,
} from '@chakra-ui/react';
import { updateCollabStatus } from 'services/firebaseService';
import { notifyInfluencerTracking } from 'services/flaskService';
import { arrayUnion } from 'firebase/firestore';

interface UpdateTrackingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  collabId: string | null;
}

const UpdateTrackingModal: React.FC<UpdateTrackingModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  collabId,
}) => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!trackingNumber.trim() || !collabId || isLoading) return;

    try {
      setIsLoading(true);
      // Update Firebase
      await updateCollabStatus(collabId, null, {
        trackingNumbers: arrayUnion(trackingNumber.trim())
      });

      // Notify the influencer
      await notifyInfluencerTracking(collabId, [trackingNumber.trim()]);
      
      setTrackingNumber(''); // Clear input
      onClose(); // Just close the tracking modal
      
      // Don't call onConfirm() which triggers refreshCollabData
    } catch (error) {
      console.error('Error updating tracking number:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.600" />
      <ModalContent bg="white" borderColor="gray.200" borderWidth="1px">
        <ModalHeader color="black">Add Tracking</ModalHeader>
        <ModalCloseButton color="black" />
        <ModalBody>
          <Input
            type="text"
            placeholder="Tracking Number"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
            _focus={{ borderColor: "black" }}
          />
        </ModalBody>
        <ModalFooter>
          <Button 
            bg="black" 
            color="white" 
            mr={3} 
            onClick={handleSubmit}
            _hover={{ bg: "gray.800" }}
            isDisabled={isLoading}
          >
            {isLoading ? <Spinner size="sm" /> : "Add"}
          </Button>
          <Button 
            variant="outline" 
            onClick={onClose}
            borderColor="gray.300"
            color="black"
            _hover={{ bg: "gray.100" }}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateTrackingModal;
