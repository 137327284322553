import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Icon,
  Image,
  AspectRatio,
  SimpleGrid,
  useDisclosure,
  IconButton,
  Link,
  VStack
} from '@chakra-ui/react';
import InstagramProfilePic from './InstagramProfilePic';
import { FaMapMarkerAlt, FaInstagram } from 'react-icons/fa';
import { RiContactsBookLine } from 'react-icons/ri';
import { FaPlay, FaPause } from 'react-icons/fa';
import { FaHeart, FaComment } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';
import { CollaborationsData, Influencer } from '../types';

interface MediaItem {
  userLink: string;
  verifiedUGCLink: string;
}

interface SubmissionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  collaboration: CollaborationsData;
  influencer: Influencer;
}

const SubmissionsModal: React.FC<SubmissionsModalProps> = ({ isOpen, onClose, collaboration, influencer }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedMedia, setSelectedMedia] = useState<MediaItem | null>(null);
  const { isOpen: isExpandedViewOpen, onOpen: onExpandedViewOpen, onClose: onExpandedViewClose } = useDisclosure();
  const videoRefs = useRef<{ [key: string]: HTMLVideoElement | null }>({});
  const [playingVideos, setPlayingVideos] = useState<{ [key: string]: boolean }>({});
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (isExpandedViewOpen && videoRef.current && selectedVideo) {
      videoRef.current.play().catch(error => console.error("Error playing video:", error));
    }
  }, [isExpandedViewOpen, selectedVideo]);

  // Early return if collaboration data is missing or incomplete
  if (!collaboration || !collaboration.deliverableLinks) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Text>No data available for this collaboration.</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  const isVideo = (url: string) => url.includes('.mp4') || url.includes('.mov') || url.includes('.avi');

  const handleMediaClick = (media: MediaItem) => {
    setSelectedMedia(media);
    onExpandedViewOpen();
  };

  const handlePlayPause = (e: React.MouseEvent, mediaUrl: string) => {
    e.stopPropagation();
    setSelectedVideo(mediaUrl);
    onExpandedViewOpen();
  };

  const handleExpandedViewClose = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setSelectedVideo(null);
    setSelectedMedia(null);
    onExpandedViewClose();
  };

  const formatFollowersCount = (followers: number): string => {
    if (!followers) {
      return '0';
    }
    if (followers >= 1000 && followers < 1000000) {
      return (followers / 1000).toFixed(1) + "k";
    } else if (followers >= 1000000) {
      return (followers / 1000000).toFixed(1) + "M";
    }
    return followers.toString();
  }

  const renderMedia = (mediaArray: MediaItem[]) => {
    if (!mediaArray || mediaArray.length === 0) {
      return <Text>No media available.</Text>;
    }

    return (
      <SimpleGrid columns={[1, 2, 3]} spacing={6}>
        {mediaArray.map((media, index) => (
          <Box 
            key={index} 
            borderWidth={1} 
            borderRadius="lg" 
            overflow="hidden" 
            boxShadow="md"
            cursor="pointer"
            transition="all 0.2s"
            _hover={{ transform: 'scale(1.05)' }}
            onClick={() => handleMediaClick(media)}
          >
            <VStack spacing={3} p={4}>
              <AspectRatio ratio={1} width="100%">
                <Box>
                  {isVideo(media.verifiedUGCLink) ? (
                    <Box position="relative" width="100%" height="100%">
                      <video 
                        ref={el => videoRefs.current[media.verifiedUGCLink] = el}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <source src={media.verifiedUGCLink} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <IconButton
                        icon={<FaPlay />}
                        aria-label="Play"
                        position="absolute"
                        left="50%"
                        top="50%"
                        transform="translate(-50%, -50%)"
                        fontSize="2xl"
                        color="white"
                        background="rgba(0, 0, 0, 0.5)"
                        borderRadius="full"
                        onClick={(e) => handlePlayPause(e, media.verifiedUGCLink)}
                      />
                    </Box>
                  ) : (
                    <Image
                      src={media.verifiedUGCLink}
                      alt={`Media ${index + 1}`}
                      objectFit="cover"
                      width="100%"
                      height="100%"
                      fallbackSrc="https://via.placeholder.com/150" // Fallback image if loading fails
                    />
                  )}
                </Box>
              </AspectRatio>
              
              {collaboration.postMetrics && collaboration.postMetrics.length > 0 && (
                <>
                  <Text color="blue.500" fontSize="sm" noOfLines={2}>
                    {collaboration.postMetrics.find(metric => metric.user_link === media.userLink)?.caption?.split(' ').filter(word => word.startsWith('#')).map(hashtag => hashtag).join(' ')}
                  </Text>
                  <Flex justifyContent="space-between" width="100%">
                    {!media.userLink.includes('stories') && (
                      media.userLink.includes('instagram') ? (
                        <>
                          {media.userLink.includes('reel') && (
                            <Flex alignItems="center">
                              <Icon as={FaPlay} color="gray.500" mr={1} />
                              <Text color="black" fontSize="sm">
                                {formatFollowersCount(Number(collaboration.postMetrics.find(metric => metric.user_link === media.userLink)?.play_count))}
                              </Text>
                            </Flex>
                          )}
                          <Flex alignItems="center">
                            <Icon as={FaHeart} color="gray.500" mr={1} />
                            <Text color="black" fontSize="sm">
                              {formatFollowersCount(collaboration.postMetrics.find(metric => metric.user_link === media.userLink)?.like_count)}
                            </Text>
                          </Flex>
                          <Flex alignItems="center">
                            <Icon as={FaComment} color="gray.500" mr={1} />
                            <Text color="black" fontSize="sm">
                              {formatFollowersCount(collaboration.postMetrics.find(metric => metric.user_link === media.userLink)?.comment_count)}
                            </Text>
                          </Flex>
                        </>
                      ) : (
                        <>
                          <Flex alignItems="center">
                            <Icon as={FaPlay} color="gray.500" mr={1} />
                            <Text color="black" fontSize="sm">
                              {formatFollowersCount(Number(collaboration.postMetrics.find(metric => metric.user_link === media.userLink)?.play_count))}
                            </Text>
                          </Flex>
                          <Flex alignItems="center">
                            <Icon as={FaHeart} color="gray.500" mr={1} />
                            <Text color="black" fontSize="sm">
                              {formatFollowersCount(collaboration.postMetrics.find(metric => metric.user_link === media.userLink)?.like_count)}
                            </Text>
                          </Flex>
                          <Flex alignItems="center">
                            <Icon as={FaComment} color="gray.500" mr={1} />
                            <Text color="black" fontSize="sm">
                              {formatFollowersCount(collaboration.postMetrics.find(metric => metric.user_link === media.userLink)?.comment_count)}
                            </Text>
                          </Flex>
                        </>
                      )
                    )}
                  </Flex>
                </>
              )}
            </VStack>
          </Box>
        ))}
      </SimpleGrid>
    );
  };

  const allMedia = [
    ...(collaboration.deliverableLinks.instagramPosts || []),
    ...(collaboration.deliverableLinks.instagramReels || []),
    ...(collaboration.deliverableLinks.instagramStories || []),
    ...(collaboration.deliverableLinks.tiktoks || []),
  ];

  const hasInstagramPosts = (collaboration.deliverableLinks.instagramPosts || []).length > 0;
  const hasInstagramReels = (collaboration.deliverableLinks.instagramReels || []).length > 0;
  const hasInstagramStories = (collaboration.deliverableLinks.instagramStories || []).length > 0;
  const hasTikToks = (collaboration.deliverableLinks.tiktoks || []).length > 0;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent maxW="60vw" maxH="90vh" border="2px solid #B8E79E">
          <ModalCloseButton />
          <ModalBody pt={16} pb={12} px={16}>
            <Flex alignItems="center" mb={8}>
            <InstagramProfilePic
              profile_pic_url={influencer?.profile_pic_url}
              instagramHandle={influencer?.instagramHandle}
              imageSize="120px"
            />
              <Box ml={8}>
                <Text fontWeight="bold" fontSize="xl">@{influencer?.instagramHandle}</Text>
                  {influencer?.city && influencer?.state && (
                  <Flex alignItems="center" mt={2}>
                    <Icon as={FaMapMarkerAlt} color="gray.500" mr={2} />
                    <Text fontSize="md">{influencer?.city}, {influencer?.state}</Text>
                  </Flex>
                )}
                <Flex alignItems="center" mt={2}>
                  <Icon as={FaInstagram} color="gray.500" mr={2} />
                  <Text fontSize="md">{formatFollowersCount(influencer?.follower_count)} followers</Text>
                </Flex>
              </Box>
            </Flex>

            {allMedia.length > 0 ? (
              <Tabs variant="line" colorScheme="blue" onChange={(index) => setTabIndex(index)}>
                <TabList mb="1.5em">
                  {(hasInstagramPosts || hasInstagramReels || hasInstagramStories || hasTikToks) && <Tab fontWeight="semibold">All</Tab>}
                  {hasTikToks && <Tab fontWeight="semibold">TikToks</Tab>}
                  {hasInstagramReels && <Tab fontWeight="semibold">Instagram Reels</Tab>}
                  {hasInstagramStories && <Tab fontWeight="semibold">Instagram Stories</Tab>}
                  {hasInstagramPosts && <Tab fontWeight="semibold">Instagram Posts</Tab>}
                </TabList>

                <TabPanels>
                  {(hasInstagramPosts || hasInstagramReels || hasInstagramStories || hasTikToks) && (
                    <TabPanel>
                      <Box height="45vh" overflowY="auto">
                        {renderMedia(allMedia)}
                      </Box>
                    </TabPanel>
                  )}
                  {hasTikToks && (
                    <TabPanel>
                      <Box height="45vh" overflowY="auto">
                        {renderMedia(collaboration.deliverableLinks.tiktoks)}
                      </Box>
                    </TabPanel>
                  )}
                  {hasInstagramReels && (
                    <TabPanel>
                      <Box height="45vh" overflowY="auto">
                        {renderMedia(collaboration.deliverableLinks.instagramReels)}
                      </Box>
                    </TabPanel>
                  )}
                  {hasInstagramStories && (
                    <TabPanel>
                      <Box height="45vh" overflowY="auto">
                        {renderMedia(collaboration.deliverableLinks.instagramStories)}
                      </Box>
                    </TabPanel>
                  )}
                  {hasInstagramPosts && (
                    <TabPanel>
                      <Box height="45vh" overflowY="auto">
                        {renderMedia(collaboration.deliverableLinks.instagramPosts)}
                      </Box>
                    </TabPanel>
                  )}
                </TabPanels>
              </Tabs>
            ) : (
              <Text>No media available for this collaboration.</Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Expanded Media Modal */}
      <Modal isOpen={isExpandedViewOpen} onClose={handleExpandedViewClose} size="xl" isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 1)" />
        <ModalContent bg="black" maxW="80vw" maxH="80vh" position="relative">
          <ModalCloseButton 
            color="white" 
            onClick={handleExpandedViewClose}
            position="absolute"
            right="8px"
            top="8px"
            zIndex="1"
          />
          <ModalBody display="flex" justifyContent="center" alignItems="center" p={4}>
            <Box width="100%" height="100%">
              {selectedVideo && (
                <AspectRatio ratio={16 / 9} width="100%" height="100%">
                  <video 
                    ref={videoRef}
                    controls 
                    autoPlay
                    muted
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  >
                    <source src={selectedVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </AspectRatio>
              )}
              {selectedMedia && !isVideo(selectedMedia.verifiedUGCLink) && (
                <AspectRatio ratio={1 / 1} width="100%" height="100%">
                  <Image
                    src={selectedMedia.verifiedUGCLink}
                    alt="Expanded Media"
                    objectFit="contain"
                    width="100%"
                    height="100%"
                  />
                </AspectRatio>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubmissionsModal;
