import React from 'react';
import { Box, Text, Flex, Icon, VStack, HStack, Avatar } from '@chakra-ui/react';
import { FaChevronRight, FaExclamationCircle } from 'react-icons/fa';
import { CollaborationsData, Influencer } from '../types';
import moment from 'moment';

interface UpcomingCollabsProps {
  collabs: Record<string, CollaborationsData>;
  influencers: Record<string, Influencer>;
  onViewAll: (tabIndex?: number) => void;
}

const UpcomingCollabs: React.FC<UpcomingCollabsProps> = ({ collabs, influencers, onViewAll }) => {
  const sortedCollabs = Object.values(collabs)
    .filter(collab => 
      (collab.status === 'pendingRedemption' || 
      collab.status === 'collabRedeemed' ||
      collab.status === 'pendingSubmission') &&
      !collab.shippingConfirmed
    )
    .sort((a, b) => {
      // First sort by shipping (shipping collabs first)
      if (a.shippingAddress && !b.shippingAddress) return -1;
      if (!a.shippingAddress && b.shippingAddress) return 1;
      
      // Then sort by date
      if (!a.scheduledCollabDate && !b.scheduledCollabDate) return 0;
      if (!a.scheduledCollabDate) return 1;
      if (!b.scheduledCollabDate) return -1;
      return moment(a.scheduledCollabDate.toDate()).diff(moment(b.scheduledCollabDate.toDate()));
    });

  const renderCollabList = (collabs) => (
    <VStack 
      spacing={0} 
      align="stretch" 
      overflow="auto" 
      maxHeight="calc(300px - 60px)"
    >
      {collabs.map((collab) => {
        const influencer = influencers[collab.influencerID];
        
        // Determine what text to display
        let displayText;
        const needsShipping = collab.status === 'pendingSubmission' && collab.shippingAddress;
        if (needsShipping) {
          displayText = `📦 ${collab.shippingAddress}`;
        } else if (collab.scheduledCollabDate) {
          displayText = moment(collab.scheduledCollabDate.toDate()).format('MMM D, h:mm A');
        } else {
          displayText = 'Date not set';
        }

        return (
          <Flex
            key={collab.id}
            p={4}
            borderBottom="1px solid"
            borderColor="gray.100"
            align="center"
            justify="space-between"
          >
            <HStack spacing={3}>
              <Avatar 
                size="sm"
                src={influencer?.profile_pic_url} 
                name={influencer?.instagramHandle}
              />
              <Box>
                <Text fontWeight="500">
                  @{influencer?.instagramHandle || 'Unknown'}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {displayText}
                </Text>
              </Box>
            </HStack>
            {needsShipping && (
              <Icon 
                as={FaExclamationCircle} 
                color="orange.500" 
                w={5} 
                h={5} 
                title="Priority: Needs Shipping"
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onViewAll(2);
                }}
                _hover={{ transform: 'scale(1.1)' }}
                transition="transform 0.2s"
              />
            )}
          </Flex>
        );
      })}

      {collabs.length === 0 && (
        <Flex p={4} justify="center" align="center">
          <Text color="gray.500">No collaborations found</Text>
        </Flex>
      )}
    </VStack>
  );

  return (
    <Box 
      bg="white" 
      borderRadius="xl" 
      border="1px solid"
      borderColor="gray.300"
      width="100%"
      height="300px"
      overflow="hidden"
    >
      <Flex 
        justify="space-between" 
        align="center" 
        p={4} 
        borderBottom="1px solid" 
        borderColor="gray.100"
      >
        <Text fontSize="lg" fontWeight="600">
          Upcoming Collabs ({sortedCollabs.length})
        </Text>
        <Box
          as="button"
          display="flex"
          alignItems="center"
          gap={1}
          px={3}
          py={1}
          borderRadius="full"
          border="1px solid"
          borderColor="gray.200"
          bg="white"
          _hover={{ bg: "gray.50" }}
          cursor="pointer"
          onClick={() => onViewAll()}
        >
          <Text fontSize="sm" color="gray.600">View All</Text>
          <Icon as={FaChevronRight} w={3} h={3} color="gray.600" />
        </Box>
      </Flex>

      {renderCollabList(sortedCollabs)}
    </Box>
  );
};

export default UpcomingCollabs;