import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  Select,
  Spinner,
  Icon,
  Stack,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup
} from "@chakra-ui/react";
import { FaStar, FaChevronDown } from "react-icons/fa6";
import { AccountsDataContext } from "contexts/accountsDataContext";
import AccountsDropdown from "../../components/accountsDropDown";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import InfluencersList from './components/InfluencersList'
import Pagination from './components/Pagination';
import { useLocation, useHistory } from "react-router-dom";
import { setCampaignInfluencers, createCollaboration, getInfluencerCampaign, getClientCampaignsAndCollabs, getAllInfluencers } from "services/firebaseService";
import { getDocFromCache } from "firebase/firestore";
import Environment from "environment";
import { Influencer, InfluencerCampaign } from "../../types";
import { AdminDataContext } from "contexts/adminDataContext";
import { PaymentModal } from './components/PaymentModal';


interface SearchProps {
  refreshCollabData: () => void;
}

export default function Search({ refreshCollabData }: SearchProps) {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  const clientID = queryParams.get("clientID");
  const campaignID = queryParams.get("campaignID");

  const client = useContext(AccountsDataContext)[clientID];

  const {adminData, isLoading} = useContext(AdminDataContext)


  
  
  const [influencerCampaigns, setInfluencerCampaigns] = useState<InfluencerCampaign[] >([]);

  const [influencers, setInfluencers] = useState<Record<string, Influencer>>({});
  
  useEffect(() => {
    const fetchInfluencers = async () => {
      try {
        const fetchedInfluencers = await getAllInfluencers();
        setInfluencers(fetchedInfluencers);
      } catch (error) {
        console.error("Error fetching influencers:", error);
      }
    };

    fetchInfluencers();
  }, []);

  const [selectedAccountName, setSelectedAccountName] = useState<string>("");

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [filterBookmarked, setFilterBookmarked] = useState(false);
  const [filterSelected, setFilterSelected] = useState(false);
  const [filterFeatured, setFilterFeatured] = useState(false);
  const [followerRange, setFollowerRange] = useState("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const [visibleInfluencers, setVisibleInfluencers] = useState<Influencer[][]>([[]]);
  const [currPage, setCurrPage] = useState(0);

  const [selectedInfluencers, setSelectedInfluencers] = useState<Set<string>>(new Set());
  const [isFinishButtonDisabled, setIsFinishButtonDisabled] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [isLaunching, setIsLaunching] = useState(false);
  
  const toggleInfluencerSelected = (influencerID: string) => {
    if (adminData.remainingCollabRequests - totalCreators - selectedInfluencers.size <= 0 && !selectedInfluencers.has(influencerID)) {
      return;
    }
    setSelectedInfluencers(prevSelected => {
      const updatedSet = new Set(prevSelected);
      if (updatedSet.has(influencerID)) {
        updatedSet.delete(influencerID);
      } else {
        updatedSet.add(influencerID);
      }
      return updatedSet;
    });
  }

  useEffect(() => {
    const fetchCampaigns = async () => {
      const fetchedCampaigns = [];

      for (const clientId of adminData.clientIDs) {
        const campaigns = await getClientCampaignsAndCollabs(clientId);
        fetchedCampaigns.push(...Object.values(campaigns));
      }
      setInfluencerCampaigns(fetchedCampaigns);
    };
    fetchCampaigns();
  }, [adminData, campaignID]);

  useEffect(() => {
    const filteredInfluencers = getFilteredInfluencers();
    const influencersByPage = groupInfluencersByPage(filteredInfluencers ?? [], 50); // Increased from 20 to 50
    setVisibleInfluencers(influencersByPage);
    setCurrPage(0);
  }, [influencers, searchTerm, selectedState, selectedCity, filterBookmarked, filterSelected, filterFeatured, followerRange, client.id, influencerCampaigns, selectedTags]);

  const getActiveCreators = (influencerCampaign: InfluencerCampaign) => {
    if (!influencerCampaign.collaborations) {
      return new Set<string>();
    }
    const activeInfluencers = new Set(
      Object.values(influencerCampaign.collaborations)
        .filter(collab => collab.influencerCampaignID === influencerCampaign.id)
        .map(collab => collab.influencerID)
    );
    
    return activeInfluencers;
  };

  const getFilteredInfluencers = () => {
    const activeCreators = new Set<string>();
    
    // Only get active creators for the current campaign
    if (campaignID) {
      const currentCampaign = influencerCampaigns.find(campaign => campaign.id === campaignID);
      if (currentCampaign) {
        getActiveCreators(currentCampaign).forEach(creatorId => activeCreators.add(creatorId));
      }
    }

    const filtered = Object.keys(influencers ?? {})
      .map(key => influencers[key])
      .filter(influencer => {
        // Only filter out influencers who are part of the current campaign
        if (campaignID && activeCreators.has(influencer.id)) {
          return false;
        }
        if (campaignID && (!influencer.email_address || influencer.email_address.trim() === '')) {
          return false;
        }
        influencer.name = influencer.firstName + (influencer.lastName != "unknown" ? " " + influencer.lastName : "");
        const matchesSearchTerm =
          influencer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          influencer.email_address.toLowerCase().includes(searchTerm.toLowerCase()) ||
          influencer.instagramHandle.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesState = selectedState ? influencer.state === selectedState : true;
        const matchesCity = selectedCity ? influencer.city === selectedCity : true;
        const matchedBookmark = filterBookmarked ? client.bookmarkedInfluencers && client.bookmarkedInfluencers.includes(influencer.id) : true;
        const matchedSelected = filterSelected ? selectedInfluencers.has(influencer.id) : true;
        const matchedFeatured = filterFeatured ? influencer.featured == filterFeatured : true;
        const matchedFollowerRange = followerRange ? (followerRanges[followerRange][0] <= influencer.follower_count && influencer.follower_count <= followerRanges[followerRange][1]) : true;
        const matchedTags = selectedTags.length === 0 || (influencer.tags && influencer.tags.some(tag => selectedTags.includes(tag)));
        return matchesSearchTerm && matchesState && matchesCity && matchedBookmark && matchedSelected && matchedFeatured && matchedFollowerRange && matchedTags;
      });
    return filtered.sort((a, b) => {
      return b.featured === a.featured ? 0 : b.featured ? 1 : -1;
    });
  }

  const totalCreators = Object.values(influencerCampaigns).reduce((total: number, campaign) => {
      return total + getActiveCreators(campaign).size;
  }, 0);


  const groupInfluencersByPage = (influencers, groupSize) => {
    let groups = [];
    for (let i = 0; i < influencers.length; i += groupSize) {
      groups.push(influencers.slice(i, i + groupSize));
    }
    if (groups.length == 0) {
      groups = [[]];
    }
    return groups;
  }

  const handleAccountNameChange = (name: string) => {
    setSelectedAccountName(name);
  };

  const uniqueStates = new Set<string>();
  const uniqueCities = new Set<string>();
  const uniqueTags = new Set<string>();
  Object.values(influencers ?? {}).forEach((influencer) => {
    uniqueStates.add(influencer.state);
    if (selectedState === influencer.state) {
      if (influencer.city != "") {
        const cities = influencer.city.split(", ");
        cities.forEach((city) => {
          uniqueCities.add(city);
        });
      }
    }
    if (influencer.tags) {
      influencer.tags.forEach(tag => uniqueTags.add(tag));
    }
  });

  const followerRanges = {
    "": [0, Infinity],
    "Nano (0 - 10k)": [0, 10000],
    "Micro (10k - 50k)": [10000, 50000],
    "Macro (50k - 500k)": [50000, 500000],
    "Celebrity (500k+)": [500000, Infinity]
  };

  const handleCampaignLaunched = async () => {
    setIsLaunching(true);
    setIsFinishButtonDisabled(true);
    
    try {
      const campaignData = await getInfluencerCampaign(campaignID);
      console.log("Full campaign data:", campaignData);

      if (!campaignData) {
        console.error(`Campaign with ID ${campaignID} not found`);
        setIsFinishButtonDisabled(false);
        return;
      }

      for (const influencerID of selectedInfluencers) {
        if (adminData.remainingCollabRequests <= 0) {
          console.log("No more collab requests remaining. Stopping process.");
          break;
        }

        try {
          const influencer = influencers[influencerID];
          if (!influencer) {
            console.error(`Influencer with ID ${influencerID} not found`);
            continue;
          }
          
          await createCollaboration(
            `${influencerID}_${campaignID}`,
            influencerID,
            campaignID,
            campaignData.cash,
            campaignData.credit,
            campaignData.deliverableCounts,
            {},
            [],
            [],
            "pendingInfluencerApproval"
          );
          console.log(`Collaboration created for influencer ${influencerID}`);

          try {
            const response = await fetch(`${Environment.FLASK_HOST_URL_FOODFLUENCE}/send-influencer-collab-email/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': Environment.FLASK_API_KEY
              },
              body: JSON.stringify({
                email: influencer.email_address,
                client_name: client.accountName,
                ig_username: influencer.instagramHandle
              }),
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
        
            const data = await response.json();
            console.log('API response:', data);
          } catch (error) {
            console.error('Error calling the API:', error);
          }
        } catch (error) {
          console.error(`Error creating collaboration for influencer ${influencerID}:`, error);
        }
      }

      // Send notification email
      try {
        const response = await fetch(`${Environment.FLASK_HOST_URL_FOODFLUENCE}/send-campaign-created-notification-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': Environment.FLASK_API_KEY
          },
          body: JSON.stringify({
            client_name: client.accountName,
            campaign_name: campaignData['name']
          }),
        });
        if (!response.ok) {
          throw new Error('Failed to send notification email');
        }
        const data = await response.json();
        console.log('Notification email sent:', data);
      } catch (error) {
        console.error('Error sending notification email:', error);
      }

      console.log(`Updated client's remaining collab requests to: ${adminData.remainingCollabRequests}`);
      await refreshCollabData();
      
      // Add a small delay for better UX
      await new Promise(resolve => setTimeout(resolve, 1000));
      history.goBack();
    } catch (error) {
      console.error('Error launching campaign:', error);
      setIsFinishButtonDisabled(false);
    } finally {
      setIsLaunching(false);
    }
  };

  const handleFinishClick = async () => {
    try {
      const campaignData = await getInfluencerCampaign(campaignID);
      
      if (campaignData && campaignData.cash && campaignData.cash > 0) {
        // Calculate the payment amount based on your business logic
        const amount = selectedInfluencers.size * campaignData.cash; // Example: $10 per influencer
        setPaymentAmount(amount);
        setIsPaymentModalOpen(true);
      } else {
        // If the reward type is not cash, proceed without opening the payment modal
        handleCampaignLaunched();
      }
    } catch (error) {
      console.error("Error fetching campaign data:", error);
      // Handle error appropriately
    }
  };

  const handlePaymentSuccess = async () => {
    setIsPaymentModalOpen(false);
    await handleCampaignLaunched();
  };

  const remainingCollabRequests = adminData.remainingCollabRequests - totalCreators - selectedInfluencers.size;

  return (
    <Box>
      <Box
        backgroundColor="white"
        borderRadius="10px"
        height="calc(100vh - 60px)"
        position="relative"
        display="flex"
        flexDirection="column"
        p="20px" // Added padding here
      >
        <Box left={0} width="100%">
          <Flex justifyContent="space-between" alignItems="flex-start" mb="10px">
            <Box>
              <Text mb="4px" fontWeight="bold" fontSize="20px">{campaignID ? "Select Influencers" : "Find Influencers"}</Text>
              <Text fontSize="sm" color="gray.500" mb="8px">
                <Text as="span" color={remainingCollabRequests < 10 ? "red.500" : (remainingCollabRequests) < 31 ? "yellow.500" : "green.500"}>{remainingCollabRequests}</Text> Collab Requests Remaining
              </Text>
            </Box>
            {campaignID && (
              <Flex direction="column" align="flex-end" gap={2}>
                <Button
                  colorScheme="green"
                  size="lg"
                  variant="solid"
                  onClick={handleFinishClick}
                  isDisabled={isFinishButtonDisabled}
                  isLoading={isLaunching}
                  loadingText="Launching Campaign"
                >
                  Finish
                </Button>
                <Text fontSize="sm" color="gray.500">
                  {isLaunching ? "Processing your request..." : "You can add influencers later"}
                </Text>
              </Flex>
            )}
          </Flex>

          <Box>
            <Input
              placeholder="Search by name, email, or Instagram handle"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              mb="10px"
              bg="gray.200"
              _placeholder={{ color: 'gray.400' }}
            />

            <Flex gap="10px" mb="10px">
              <Select
                placeholder="State / Country"
                borderWidth={"thin"}
                borderColor="black"
                borderRadius={10}
                value={selectedState}
                onChange={(e) => {
                  setSelectedState(e.target.value);
                  setSelectedCity("");
                }}
                width={200}
              >
                {[...uniqueStates].sort().map((state) => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </Select>
              <Select
                placeholder="City"
                borderWidth={"thin"}
                borderColor="black"
                borderRadius={10}
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}  
                isDisabled={!selectedState}
                width={200}
              >
                {[...uniqueCities].sort().map((city, index) => (
                  <option key={index} value={city}>{city}</option>
                ))}
              </Select>
              <Select
                placeholder="Follower Count"
                borderWidth={"thin"}
                borderColor="black"
                borderRadius={10}
                value={followerRange}
                onChange={(e) => setFollowerRange(e.target.value)}
                width={200}
              >
                {Object.keys(followerRanges).map((text, index) => (
                  text != "" ?
                    (<option key={index} value={text}>{text}</option>) : (<></>)
                ))}
              </Select>
              <Menu closeOnSelect={false}>
                <MenuButton as={Button} rightIcon={<FaChevronDown />} borderWidth={"thin"} borderColor="black" borderRadius={10} bg="white" color="black" _hover={{ bg: "gray.100" }} _active={{ bg: "gray.200" }}>
                  <Text fontSize="md" fontWeight="normal">Tags</Text>
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="auto">
                  <MenuOptionGroup type="checkbox" value={selectedTags} onChange={(values) => setSelectedTags(values as string[])}>
                    {[...uniqueTags].sort().map((tag) => (
                      <MenuItemOption key={tag} value={tag}>{tag}</MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
              <Button
                rightIcon={<Icon as={FaStar} color={filterFeatured ? "white" : "#0080FE"} />}
                borderWidth={"thin"}
                borderColor={filterFeatured ? "green" : "black"}
                backgroundColor={filterFeatured ? "green" : "white"}
                color={filterFeatured ? "white" : "black"}
                onClick={() => setFilterFeatured(!filterFeatured)}
                fontWeight={"normal"}
                borderRadius={10}
                variant="ghost"
                _hover={{ borderColor: "grey.400" }}
              >
                Featured
              </Button>
              <Button onClick={() => setFilterBookmarked(!filterBookmarked)} variant="ghost">
                {filterBookmarked ?
                  (<FaBookmark color="green" size="20px" />) : (<FaRegBookmark size="20px"/>)
                }
              </Button>
              {campaignID ? ( 
                <Box ml={3} alignSelf={"center"}>
                  <Checkbox 
                    isChecked={filterSelected}
                    onChange={() => setFilterSelected(!filterSelected)}
                    colorScheme='green'
                    border={filterSelected ? "0px solid" : "1px solid"}
                    borderRadius="sm"
                    size='lg'
                  />
                </Box>
              ) : (<></>)}  
            </Flex>
          </Box>
        </Box>

        <Box overflow="auto" flex="1" p="10px" maxHeight="calc(100vh - 250px)">
          <InfluencersList 
            key={currPage} 
            influencers={visibleInfluencers[currPage]} 
            bookmarkedInfluencers={client.bookmarkedInfluencers}
            clientID={client.id} 
            creatingCampaign={campaignID!=null} 
            handleInfluencerSelected={toggleInfluencerSelected}
            selectedInfluencers={selectedInfluencers} 
          />
        </Box>
        <Pagination
          currPage={currPage}
          setCurrPage={setCurrPage}
          totalPages={visibleInfluencers.length}
        />
      </Box>
      <PaymentModal 
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        onPaymentMethodSaved={handlePaymentSuccess}
        clientId={clientID}
      />
      {isLaunching && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="blackAlpha.300"
          zIndex={1000}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            bg="white"
            p={6}
            borderRadius="md"
            boxShadow="xl"
            textAlign="center"
          >
            <Spinner size="xl" mb={4} color="green.500" />
            <Text fontSize="lg" fontWeight="medium">
              Launching Campaign...
            </Text>
            <Text fontSize="sm" color="gray.500" mt={2}>
              Please wait while we process your request
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
}
