import React, { useEffect, useState } from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  VStack,
  HStack,
  Avatar,
  Flex,
  IconButton,
  Link,
  Badge,
  Button
} from '@chakra-ui/react';
import moment from 'moment';
import { FaInstagram, FaEnvelope, FaTiktok } from 'react-icons/fa';

import { CollaborationsData, Influencer, InfluencerCampaign, collabStates } from '../types';
import ShippingConfirmationModal from './shippingConfirmationModal';
import UpdateTrackingModal from './updateTrackingModal';

interface CollabCalendarProps {
  collaborations: Record<string, CollaborationsData>;
  influencers: Record<string, Influencer>;
  campaigns: Record<string, InfluencerCampaign>;
  isOpen: boolean;
  onClose: () => void;
  setUpcomingCollabsCount: (count: number) => void;
  global: boolean;
  businessType?: string;
  refreshCollabData: () => void;
  initialTab?: number;
}

const CollabCalendar: React.FC<CollabCalendarProps> = ({ collaborations, influencers, campaigns, isOpen, onClose, setUpcomingCollabsCount, global, businessType, refreshCollabData, initialTab = 0 }) => {
  const [currentTab, setCurrentTab] = useState(initialTab);
  const [isShippingModalOpen, setIsShippingModalOpen] = useState(false);
  const [selectedCollabId, setSelectedCollabId] = useState<string | null>(null);
  const [isTrackingModalOpen, setIsTrackingModalOpen] = useState(false);

  const sortedCollabs = Object.values(collaborations).sort((a, b) => {
    if (!a.scheduledCollabDate && !b.scheduledCollabDate) return 0;
    if (!a.scheduledCollabDate) return 1;
    if (!b.scheduledCollabDate) return -1;
    return moment(a.scheduledCollabDate.toDate()).diff(moment(b.scheduledCollabDate.toDate()));
  });

  const awaitingShipmentCollabs = sortedCollabs.filter(collab => 
    collab.shippingAddress && (!collab.shippingConfirmed || collab.shippingConfirmed)
  ).sort((a, b) => {
    // Sort by shipping confirmation status - unconfirmed first
    if (!a.shippingConfirmed && b.shippingConfirmed) return -1;
    if (a.shippingConfirmed && !b.shippingConfirmed) return 1;
    return 0;
  });

  const upcomingCollabs = sortedCollabs.filter(collab => 
    collab.status == 'pendingRedemption' || 
    collab.status == 'collabRedeemed'
  );

  useEffect(() => {
    setUpcomingCollabsCount(upcomingCollabs.length);
  }, [upcomingCollabs.length]);

  const unconfirmedCollabs = sortedCollabs.filter(collab => 
    collab.status == 'pendingInfluencerApproval'
  ); 

  const pastCollabs = sortedCollabs.filter(collab => 
    collab.status == 'pendingSubmission' || collab.status == 'pendingVerification' || collab.status == 'collabCompleted'
  );

  const canceledCollabs = sortedCollabs.filter(collab => 
    collab.status === 'collabCanceled' || collab.status === 'collabExpired'
  );

  const handleShipmentConfirm = async () => {
    await refreshCollabData();
    setIsShippingModalOpen(false);
    setSelectedCollabId(null);
    setCurrentTab(0);
  };

  const handleTrackingConfirm = async () => {
    await refreshCollabData();
    setIsTrackingModalOpen(false);
    setSelectedCollabId(null);
  };

  const renderCollabList = (collabs: CollaborationsData[], showShippingAddress: boolean = false) => (
    <VStack 
      align="stretch" 
      spacing={4} 
      overflowY="auto" 
      height="60vh"
      minHeight="60vh"
    >
      {collabs.map((collab) => {
        const influencer = influencers[collab.influencerID];
        return (
          <HStack key={collab.id} spacing={4} p={4} borderWidth={1} borderRadius="md">
            <Avatar 
              src={influencer?.profile_pic_url} 
              name={influencer?.instagramHandle}
              size="lg"
            />
            <Box flex={1}>
              <Flex alignItems="center">
                <Text fontWeight="bold">@{influencer?.instagramHandle}</Text>
                <Flex ml={2}>
                  {influencer?.instagramHandle && (
                    <IconButton
                      icon={<FaInstagram />}
                      aria-label="Instagram"
                      variant="ghost"
                      colorScheme="gray"
                      as={Link}
                      href={`https://instagram.com/${influencer.instagramHandle}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )}
                  {influencer?.tiktokHandle && (
                    <IconButton
                      icon={<FaTiktok />}
                      aria-label="TikTok"
                      variant="ghost"
                      colorScheme="gray"
                      as={Link}
                      href={`https://www.tiktok.com/@${influencer.tiktokHandle}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )}
                </Flex>
              </Flex>
              <Text fontSize="sm">
                {showShippingAddress 
                  ? `Shipping Address: ${collab.shippingAddress || 'No address provided'}`
                  : collab.scheduledCollabDate 
                    ? moment(collab.scheduledCollabDate.toDate()).format('MMMM D, YYYY, h:mm A')
                    : 'Date and time not set'
                }
              </Text>
              {global && <Text fontSize="sm" color="gray.500">{campaigns[collab.influencerCampaignID]?.clientName}</Text>}
              <Text fontSize="sm" color="gray.500">{campaigns[collab.influencerCampaignID]?.name}</Text>
            </Box>
            {collab.shippingAddress && !collab.shippingConfirmed ? (
              <Badge
                as={Button}
                bg="black"
                color="white"
                onClick={() => {
                  setSelectedCollabId(collab.id);
                  setIsShippingModalOpen(true);
                }}
                px={2}
                py={0.5}
                borderRadius="md"
                fontSize="sm"
                fontWeight="medium"
                height="24px"
                textTransform="capitalize"
              >
                Confirm Shipment
              </Badge>
            ) : collab.shippingAddress && collab.shippingConfirmed ? (
              <Badge
                as={Button}
                bg="green.100"
                onClick={() => {
                  setSelectedCollabId(collab.id);
                  setIsTrackingModalOpen(true);
                }}
                px={2}
                py={0.5}
                borderRadius="md"
                fontSize="sm"
                fontWeight="medium"
                height="24px"
                textTransform="capitalize"
              >
                Add Tracking
              </Badge>
            ) : (
              <Badge bg={collabStates[collab.status].color}>
                {collabStates[collab.status].label}
              </Badge>
            )}
          </HStack>
        );
      })}
    </VStack>
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="50vw">
          <ModalHeader>Collab Schedule</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs index={currentTab} onChange={setCurrentTab}>
              <TabList>
                <Tab>Upcoming ({upcomingCollabs.length})</Tab>
                {!global && <Tab>Unconfirmed ({unconfirmedCollabs.length})</Tab>}
                {(businessType === 'hybrid' || businessType === 'online') && (
                  <Tab>Shipping ({awaitingShipmentCollabs.length})</Tab>
                )}
                <Tab>Past ({pastCollabs.length})</Tab>
                <Tab>Cancelled ({canceledCollabs.length})</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>{renderCollabList(upcomingCollabs)}</TabPanel>
                {!global && <TabPanel>{renderCollabList(unconfirmedCollabs)}</TabPanel>}
                {(businessType === 'hybrid' || businessType === 'online') && (
                  <TabPanel>{renderCollabList(awaitingShipmentCollabs, true)}</TabPanel>
                )}
                <TabPanel>{renderCollabList(pastCollabs)}</TabPanel>
                <TabPanel>{renderCollabList(canceledCollabs)}</TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>

      <ShippingConfirmationModal
        collabId={selectedCollabId}
        isOpen={isShippingModalOpen}
        onClose={() => {
          setIsShippingModalOpen(false);
          setSelectedCollabId(null);
        }}
        onConfirm={handleShipmentConfirm}
        onAfterConfirm={() => setCurrentTab(0)}
      />

      <UpdateTrackingModal
        collabId={selectedCollabId}
        isOpen={isTrackingModalOpen}
        onClose={() => {
          setIsTrackingModalOpen(false);
          setSelectedCollabId(null);
        }}
        onConfirm={handleTrackingConfirm}
      />
    </>
  );
};

export default CollabCalendar;